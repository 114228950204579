<template>
  <div class="app-chat-drawer">
    <!--    <a-icon type="arrow-left" @click="closePanel" class="back-icon"/>-->
    <BackTitle @backClick="closePanel"/>
    <div class="text-content">
      <div class="template">
        <p class="p-title">场景模版</p>
        <div class="template-card-container">
          <div v-for="(item, index) in card"
               :class="curId === item.id ? 'template-card template-card-selected' : 'template-card'"
               @click="cardClick(item)">
            <img :src="'/PIC/' + item.iconName" style="width: 40px; height: 40px;"/>
            <div class="card-text">
              <div>{{ item.name }}</div>
              <div class="text-overflow">{{ item.info }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="condition">
        <p class="p-title">条件设立</p>
        <DynamicForm ref="dynamicForm" :cardData="userForm" @submitForm="submitForm" class="dynamic-form-container"/>
        <div class="button-footer">
          <a-button type="primary"
                    html-type="submit"
                    @click="submitForm"
                    :disabled="spinning"
                    style="height: 38px;width: 100%">
            智能创造文案
          </a-button>
        </div>
      </div>
      <div class="result">
        <a-tabs default-active-key="1">
          <a-tab-pane key="1">
            <span slot="tab" class="a-tab-pane">
              <icon-font type="icon-ego-fav" class="icon"/>
              新创作的
            </span>
            <a-spin size="large" :spinning="spinning">
              <RichForm :htmlData="htmlData"/>
            </a-spin>

          </a-tab-pane>
          <a-tab-pane key="2">
            <span slot="tab" class="a-tab-pane">
              <icon-font type="icon-violet_collection-01" class="icon"/>
              已收藏的
            </span>
            Tab 2
          </a-tab-pane>
          <a-tab-pane key="3">
            <span slot="tab" class="a-tab-pane">
              <icon-font type="icon-lishi" class="icon"/>
              历史记录
            </span>
            Tab 3
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </div>
</template>


<script>


import {appChat} from "@/api/chat";
import {currentTime} from "@/utils/DateUtil";

import('@/ext/extend-write')
export default ({
  props: {
    card: {
      type: Array,
      default: () => {
        return []
      }
    },
    currentId: {
      type: String,
      default: ''
    },
  },
  components: {
    DynamicForm: () => import("@/views/application/component/DynamicForm.vue"),
    RichForm: () => import("@/views/application/component/RichForm.vue"),
    BackTitle: () => import("@/components/Application/BackTitle.vue")
  },
  computed: {
    curId: {
      get() {
        return this.currentId
      },
      set(newVal) {
        this.$emit('update:currentId', newVal)
      }
    }
  },
  watch: {
    'currentId': {
      handler(newVal, oldVal) {
        const val = this.card.filter(item => item.id === newVal)
        if (val && val[0] && val[0].userForms) {
          const cur = val[0]
          cur.userForms.forEach(item => {
            if (item.optionsList && typeof (item.optionsList) === "string") {
              item.optionsList = item.optionsList.split("||")
            }
          })
          this.userForm = cur
          this.scroll()
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      userForm: {},
      htmlData: '',
      spinning: false
    }
  },
  methods: {
    //设置滚动条到底部
    scroll() {
      this.$nextTick(() => {
        let index = 0
        for (let i = 0; i < this.card.length; i++) {
          const item = this.card[i]
          if (item.id === this.curId) {
            index = i
            break;
          }
        }
        if (index > 0) {
          let container = this.$el.querySelector(".template-card-container");
          container.scrollTop = index * 100
        }
      });
    },
    closePanel() {
      this.$emit('closePanel', false)
    },
    cardClick(cur) {
      this.curId = cur.id
    },
    submitForm(data) {
      const userForms = this.$refs.dynamicForm.cardData.userForms
      let text = {}
      userForms.forEach((item, i) => {
        if (item.value) {
          text[item.label] = item.value
        }
      })
      this.spinning = true
      appChat(text, this.curId).then(res => {
        if (res && res.code === -1) {
          this.htmlData = res.message
        } else {
          this.htmlData = res
        }
        this.spinning = false
      }).finally(() => {
        this.spinning = false
      })
    }
  },

  created() {

  }
})

</script>


<style scoped lang="scss">

</style>