import request from "@/utils/request";
import {APICONFIG} from "@/utils/apiConfig";


/**
 * 建立sse连接
 */
export function chat(uid, msg, model) {
    return request({
        url: `${APICONFIG}/chat`,
        method: 'post',
        data: {msg: msg},
        headers: {
            uid: uid,
            'X-model-name': model,
            'X-consume-num': 1
        }
    })
}

/**
 * 建立sse连接
 */
export function appChat(params, id) {
    return request({
        url: `${APICONFIG}/app/chat/${id}`,
        method: 'post',
        data: params,
        headers: {
            'X-model-name': 'gpt-3.5-turbo-1106',
            'X-consume-num': 1
        }
    })
}

/**
 * 查询模型信息
 */
export function userModel() {
    return request({
        url: `${APICONFIG}/user/model`,
        method: 'get',
    })
}




